import React, { useState, useEffect } from "react";
/*
import { useTranslation } from 'react-i18next';
*/
import './Navbar.css';
import logo from '../Images/logo.png';
import { Link } from 'react-router-dom';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false); // State for the main navbar
    const [isLangOpen, setIsLangOpen] = useState(false); // State for the language dropdown
    /*
    const { i18n } = useTranslation();
    const [selectedLang, setSelectedLang] = useState("en"); // Default language
    */

    // Function to scroll to a section, considering the header height if necessary
    const scrollToSection = (sectionId, applyOffset = true) => {
        const headerHeight = 40; // Adjust this value based on your header's height
        const sectionElement = document.getElementById(sectionId);
        if (sectionElement) {
            let elementPosition = sectionElement.getBoundingClientRect().top + window.pageYOffset;
            if (applyOffset) {
                elementPosition -= headerHeight;
            }
            window.scrollTo({
                top: elementPosition,
                behavior: "smooth"
            });
        } else {
            console.warn(`Section with ID ${sectionId} not found on this page.`);
        }
    };

    // Individual scroll functions for different sections, applying offset only where needed
    const scrollToHome = () => scrollToSection('home', false); // No offset needed on the home page
    const scrollToSolutions = () => scrollToSection('solutions'); // Offset applied
    const scrollToPartners = () => scrollToSection('partners', true); // Apply offset to Partners
    const scrollToContactUs = () => scrollToSection('contactus'); // Offset applied
    const scrollToSubscription = () => scrollToSection('subscription'); // Offset applied

    /*
    // Change language function and update selected flag
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        setSelectedLang(lng); // Update selected language
        setIsLangOpen(false); // Close the language menu after selecting a language
        document.body.classList.remove("no-scroll"); // Re-enable scrolling after closing
    };
    */

    // Toggle Navbar and scrolling behavior
    const toggleNavbar = () => {
        setIsOpen(!isOpen);
        setIsLangOpen(false); // Ensure the language menu is closed when the navbar is toggled
        if (!isOpen) {
            document.body.classList.add("no-scroll"); // Disable scrolling when navbar is open
        } else {
            document.body.classList.remove("no-scroll"); // Re-enable scrolling when navbar is closed
        }
    };

    const handleheader = (event) => {
        if (event.target.closest(".frontpage")) {
            return;
        }
        if (event.target.matches(".menu-icon")) {
            toggleNavbar();
        }
    };

    /*
    // Toggle the Language Menu
    const toggleLanguageMenu = () => {
        setIsLangOpen(!isLangOpen);
    };
    */

    // Ensure that scrolling is enabled if the component unmounts or state resets
    useEffect(() => {
        return () => {
            document.body.classList.remove("no-scroll");
        };
    }, []);

    return (
        <nav className="header">
            <div className="logo-container">
                <a href="#home" onClick={(e) => { e.preventDefault(); toggleNavbar(); scrollToHome(); }}>
                    <img src={logo} alt="EXELO Logo" className="logo" />
                </a>
            </div>

            {/* Navbar Links */}
            <div className={`navbar ${isOpen ? "active" : ""}`}>
                <a className="nav-item1" href="#home" onClick={(e) => { e.preventDefault(); toggleNavbar(); scrollToHome(); }}>Home</a>
                <a className="nav-item2" href="#partners" onClick={(e) => { e.preventDefault(); toggleNavbar(); scrollToPartners(); }}>Partners</a>
                <a className="nav-item3" href="#solutions" onClick={(e) => { e.preventDefault(); toggleNavbar(); scrollToSolutions(); }}>Solutions</a>
                <a className="nav-item4" href="#subscription" onClick={(e) => { e.preventDefault(); toggleNavbar(); scrollToSubscription(); }}>Subscription</a>
                <a className="nav-item5" href="#contactus" onClick={(e) => { e.preventDefault(); toggleNavbar(); scrollToContactUs(); }}>Contact Us</a>
            </div>
                {/* Language Selector */}
                {/*
                <div className="languages">
                    <img
                        src={`/flags/${selectedLang}_flag.png`}
                        alt="Selected Language"
                        className="selected-flag"
                        onClick={toggleLanguageMenu}
                    />
                    {isLangOpen && (
                        <div className="language-selector">
                            {selectedLang !== "en" && (
                                <div onClick={() => changeLanguage("en")} style={{ cursor: "pointer" }}>
                                    <img src="/flags/en_flag.png" alt="English" />
                                </div>
                            )}
                            {selectedLang !== "so" && (
                                <div onClick={() => changeLanguage("so")} style={{ cursor: "pointer" }}>
                                    <img src="/flags/so_flag.png" alt="Somaliland" />
                                </div>
                            )}
                        </div>
                    )}
                </div>
                */}

            {/* Menu Icon with animated bars */}
            <button className={`menu-icon ${isOpen ? "open" : ""}`} onClick={toggleNavbar}>
                <div></div>
                <div></div>
                <div></div>
            </button>
        </nav>
    );
};

export default Navbar;
