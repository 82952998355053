import React, { useEffect } from "react";
import Navbar from "./Components/Navbar";
import Home from "./Pages/Home";
import Solutions from "./Pages/Solutions";
import Partners from "./Pages/Partners";
import Contactus from "./Pages/Contactus";
import Subscription from "./Pages/Subscription";
import './App.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

function App() {
    useEffect(() => {
        if (window.location.pathname === "/privacy") {
            window.location.href = "/Privacy.pdf";  // This redirects to the Privacy.pdf
        }
    }, []);

    return (
        <div>
            {/* Navigation Bar */}
            <Navbar />
            <div>
                {/* Each section on the page */}
                <section id="home">
                    <Home />
                </section>
                <section id="partners">
                    <Partners />
                </section>
                <section id="solutions">
                    <Solutions />
                </section>
                <section id="subscription">
                    <Subscription />
                </section>
                <section id="contactus">
                    <Contactus />
                </section>
            </div>
        </div>
    );
}

export default App;
