import React, { useState } from "react";
import '../Styles/Contactus.css';
import Downloadapk from "./Downloadapk";

const Contactus = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        message: ''
    });

        const [messageStatus, setMessageStatus] = useState(null); // State to store the message status

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const apiUrl = process.env.NODE_ENV === 'production'
            ? 'https://exelo.onrender.com/send-email'
            : '/email-backend/send-email';
        console.log(apiUrl)
        fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)

        })
            .then(response => {
                console.log(response);
                if (!response.ok) {
                    throw new Error('Network response was not ok ' + response.statusText);
                }
                return response.json();
            })
            .then(data => {
                console.log(data.status);
                if (data.status === 'success') {
                    setMessageStatus({ type: 'success', message: 'Message sent successfully!' });
                    setFormData({ firstName: '', lastName: '', email: '', message: '' });

                    // Automatically close the popup after 2 seconds
                    setTimeout(() => {
                        setMessageStatus(null);
                    }, 2000);
                } else {
                    setMessageStatus({ type: 'error', message: 'Failed to send the message. Please try again.' });
                }
            })
            .catch(error => {
                console.error("Error sending email:", error);
                setMessageStatus({ type: 'error', message: 'Error sending message: ' + error.message });
            });
    };


    return (
        <div className="contact-page">
            <h2>Contact Us</h2>
            <form className="contact-form" onSubmit={handleSubmit}>
                <div className="form-row">
                    <div className="form-group">
                        <label htmlFor="firstName">First Name</label>
                        <input
                            type="text"
                            id="firstName"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="lastName">Last Name</label>
                        <input
                            type="text"
                            id="lastName"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                            required
                        />
                    </div>
                </div>
                <div className="form-group form-group-email">
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group form-group-message">
                    <label htmlFor="message">Message</label>
                    <textarea
                        id="message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        required
                    />
                </div>
                <button type="submit" className="send-button">Send</button>
            </form>
            <div className="footercontainer">
                <div className="footer">
                    Powered and secured by EXELO
                    <hr className="footer-line" />
                    <a href="/Privacy.pdf" download className="privacy-link">
                        Privacy
                    </a>
                </div>
            </div>

            {messageStatus && (
                <div className={`popup ${messageStatus.type}`}>
                    <div className="popup-content">
                        <p>{messageStatus.message}</p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Contactus;